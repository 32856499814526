<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Data Company Profile'" />

    <v-list class="pb-0">
      <v-list-item
        v-for="(data, i) in content"
        :key="i"
        class="d-flex align-center px-0 mt-4"
      >
        <v-avatar size="40" class="red white--text font-weight-medium me-3">
          <span class="text-base">{{ i.charAt(0).toUpperCase() }}</span>
        </v-avatar>

        <div class="d-flex align-center flex-grow-1 flex-wrap">
          <div class="me-2">
            <div class="font-weight-medium">
              <span class="text--primary text-base me-1">{{
                i.charAt(0).toUpperCase() + i.slice(1)
              }}</span>
            </div>

            <v-list-item-subtitle class="text-xs">
              www.dompetkilat.co.id
            </v-list-item-subtitle>
          </div>

          <v-spacer></v-spacer>

          <div class="text-right">
            <h4 class="d-none d-md-block font-weight-bold text-body-2">
              {{ data.toLocaleString("id-ID") }}
            </h4>
            <h4 class="d-md-none font-weight-bold text-body-2">
              {{ numFormatter(data).toLocaleString("id-ID") }}
            </h4>
            <span class="text-caption">{{
              new Date().toLocaleDateString("id-ID")
            }}</span>
          </div>
        </div>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from "@mdi/js";
import HeaderContent from "../Header/HeaderContent.vue";

export default {
  components: { HeaderContent },

  props: {
    content: {
      type: Object,
    },
  },

  data() {
    return {
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },

  methods: {
    numFormatter(num) {
      return Math.abs(num) > 1000000000
        ? Math.sign(num) * (Math.abs(num) / 1000000000).toFixed(1) + " B"
        : Math.sign(num) * Math.abs(num);
    },
  },
};
</script>
