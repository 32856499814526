<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="formData.tkb"
      label="TKB"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.total_pinjaman"
      label="Total Pinjaman"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.peminjam"
      label="Peminjam"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.total_pinjaman_lunas"
      label="Total Pinjaman Lunas"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.total_pinjaman_tahun"
      label="Total Pinjaman Tahun"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.tahun"
      label="Tahun"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.total_outstanding"
      label="Total Outstanding"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.jumlah_pinjaman"
      label="Jumlah Pinjaman"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>

    <v-text-field
      v-model="formData.jumlah_pinjaman_aktif"
      label="Jumlah Pinjaman Aktif"
      color="primary"
      outlined
      dense
      required
    ></v-text-field>
  </v-form>
</template>

<script>
export default {
  props: {
    formControl: {
      type: Object,
    },
  },
  data: () => ({
    valid: true,
  }),

  computed: {
    formData() {
      return this.formControl;
    },
  },
};
</script>
