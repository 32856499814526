<template>
  <div>
    <v-row class="match-height">
      <v-col cols="12" md="6">
        <card-vertical-stats :content="formNewControl" />
      </v-col>

      <v-col cols="12" md="6">
        <v-card class="pa-8">
          <h2 class="pb-8">Edit Data</h2>
          <form-control :formControl="formNewControl" />
          <button-form
            :submit="submit"
            :buttonText="'Ubah Data'"
            class="pt-8"
          />
        </v-card>
      </v-col>
    </v-row>
    <popup-success :dialog="showDialog" :closeDialog="closeDialog" />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </div>
</template>

<script>
import store from "../../store/index.js";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import CardVerticalStats from "../../components/Card/CardVerticalStats.vue";
import FormControl from "../../components/Form/FormControl.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    ButtonForm,
    CardVerticalStats,
    FormControl,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      formNewControl: {},
      showDialog: false,
      showErrDialog: false,
    };
  },

  async mounted() {
    await store.dispatch("profile/fetchCompanyProfile");
    this.formNewControl = store.state.profile.companyProfileData;
    console.log(this.formNewControl);
  },

  updated() {},

  methods: {
    submit() {
      try {
        store.dispatch("profile/editCompanyProfile", this.formNewControl);
        this.showDialog = true;
      } catch (err) {
        this.showErrDialog = true;
      }
    },
    closeDialog() {
      this.showDialog = !this.showDialog;
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
  },
};
</script>
